import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import React, { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Spinner from './components/Spinner'
import { useSiteConfig } from './contexts/SiteConfigContext'
import LocaleGuard from './LocaleGuard'

const Home = lazy(() => import('./pages/Home'))
const Subtopic = lazy(() => import('./pages/Subtopic'))

const App = () => {
  const { siteConfig } = useSiteConfig()

  if (!siteConfig) return <> </>

  const gtmParams = {
    id: siteConfig.fields.gtmTagId,
  }

  return (
    <GTMProvider state={gtmParams}>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path="/:locale"
            element={<LocaleGuard component={<Home />} />}
          />
          <Route
            path="/:locale/sections/:slug"
            element={<LocaleGuard component={<Subtopic />} />}
          />
          <Route path="*" element={<Navigate to="/en" />} />
        </Routes>
      </Suspense>
    </GTMProvider>
  )
}

export default App
