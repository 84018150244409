import './index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { SiteConfigProvider } from './contexts/SiteConfigContext'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <SiteConfigProvider>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </SiteConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals()
