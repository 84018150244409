import React, { createContext, useContext, useEffect, useState } from 'react'

import ContentfulManager from '../contentfulManager'
import locales from '../locales'

const SiteConfigContext = createContext()

const SiteConfigProvider = (props) => {
  const [siteConfig, setSiteConfig] = useState()

  useEffect(async () => {
    let locale = window.location.pathname.split('/')[1]
    if (!Object.keys(locales).includes(locale)) {
      locale = 'en'
    }
    const { isoCode } = locales[locale]
    ContentfulManager.instance.setLocale(isoCode)
    const value = await ContentfulManager.instance.getSiteConfig()
    setSiteConfig(value)
  }, [])

  return <SiteConfigContext.Provider value={{ siteConfig }} {...props} />
}

const useSiteConfig = () => useContext(SiteConfigContext)

export { SiteConfigProvider, useSiteConfig }
