import { createClient } from 'contentful'

// const contentful = require('contentful')

// this is the delivery api access token
// XwGhKnQ65wmV94xY0kXv8xmGaKB_tVve0xV9EBQzrWU
// this is the preview api access token
// 41GZjnmGbMDjXTwzZUs9BNj8uER7Sb2_ChsehQLwz9I

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  host: process.env.REACT_APP_CONTENTFUL_HOST,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
})
// This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.

export default class ContentfulManager {
  static instance = ContentfulManager.instance || new ContentfulManager()

  constructor() {
    this.client = client
  }

  setLocale(locale) {
    this.locale = locale
  }

  // eslint-disable-next-line class-methods-use-this
  async getPage() {
    const params = {
      content_type: 'pages',
      include: 10,
      locale: this.locale,
    }

    try {
      const { items } = await client.getEntries(params)
      return items
    } catch (e) {
      console.log('contentful error')
      console.log(e)
    }
    return 'error'
  }

  async getSiteConfig() {
    try {
      const item = await this.client.getEntry(
        process.env.REACT_APP_CONTENTFUL_SITECONFIG_ID,
        {
          locale: this.locale,
        }
      )
      return item
    } catch (err) {
      console.log(err)
    }
    return 'err'
  }

  async getHomePage() {
    let homePageID = ''

    try {
      const item = await this.getSiteConfig()
      homePageID = item.fields.homepageEntryId.sys.id
    } catch (err) {
      console.log(err)
    }

    try {
      const item = await this.client.getEntry(homePageID, {
        locale: this.locale,
      })
      return item
    } catch (e) {
      console.log('contentful error')
      console.log(e)
    }
    return 'error'
  }

  async getSinglePage(slug) {
    // let lang = await getLanguage()
    // let country = await getCountry()
    const params = {
      content_type: 'pages',
      include: 10,
      locale: this.locale,
      'fields.slug': slug,
    }
    try {
      const { items } = await this.client.getEntries(params)

      return items[0]
    } catch (e) {
      console.log('contentful error')
      console.log(e)
    }
    return 'error'
  }
}
