import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import locales from './locales'

const LocaleGuard = ({ component }) => {
  const { locale } = useParams()

  if (!Object.keys(locales).includes(locale)) {
    return <Navigate to="/en" />
  }

  return component
}

export default LocaleGuard
