import en from './en.json'
import zhHans from './zh_hans.json'
import zhHant from './zh_hant.json'

export default {
  en: {
    code: 'en',
    isoCode: 'en',
    displayName: 'EN',
    translations: en,
  },
  tc: {
    code: 'tc',
    isoCode: 'zh-Hant',
    displayName: '繁',
    translations: zhHant,
  },
  sc: {
    code: 'sc',
    isoCode: 'zh-Hans',
    displayName: '简',
    translations: zhHans,
  },
}
