import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`

const Spinner = () => (
  <Overlay>
    <div className="spinner-border text-primary" role="status">
      {/* <span className="sr-only">Loading...</span> */}
    </div>
  </Overlay>
)

export default Spinner
